<template>
    <div>
        <div>
            <v-col cols="12" sm="4">
                <h4>Agregar Consulta de Paciente</h4>
            </v-col>
        </div>

        <template>
            <v-container fluid>
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-text-field disabled class="styled-input" v-model="currentPaciente.nombre" type="text"
                            label="Nombre"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field disabled v-model="currentPaciente.apellido" type="text"
                            label="Apellido"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field disabled v-model="currentPaciente.documento" counter="8" type="number"
                            label="Documento"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field disabled v-model="currentPaciente.telefono" type="number"
                            label="Telefono"></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-container fluid>
                        <v-textarea name="inputConsulta" variant="filled" label="Datos de la Consulta:" auto-grow
                            v-model="consulta.notasConsulta"></v-textarea>
                    </v-container>
                    <v-checkbox class="align-center justify-center" label="Requiere Seguimiento"
                        v-model="consulta.seguimientoConsulta"></v-checkbox>

                    <v-checkbox class="align-center justify-center"
                        label="El usuario activo es quien realizo la consulta"></v-checkbox>
                        
                    <v-text-field type="text" label="Consulta Realizada por:" v-model="currentUser.username" ></v-text-field>

                </v-row>
                <v-row>
                    <v-spacer></v-spacer>
                    <v-col cols="12" sm="6" md="4">
                        <v-menu v-model="menu2" :close-on-content-click="true" :nudge-right="40"
                            transition="scale-transition" offset-y min-width="290px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="date" label="Fecha de Consulta" readonly v-bind="attrs"
                                    v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="date" @input="menu2 = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>



            </v-container>
        </template>





        <div class="text-center d-flex mt-3 pb-4">
            <v-btn color="primary" @click="saveConsulta(); snackbar = true, newConsulta()">
                Registrar y volver
            </v-btn>
            <v-btn class="ml-1" width="auto" color="secondary" @click="goToList">
                Volver al Listado
            </v-btn>
            <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
        </div>

        <v-snackbar v-model="snackbar" :multi-line="multiLine">
            Actualizado correctamente a las {{ showEditedTime() }}
            <template v-slot:action="{ attrs }">
                <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import PacienteDataService from "../services/PacienteDataService";
import ConsultaDataService from "../services/ConsultaDataService";
import ConfirmDialogue from './ConfirmDialogue.vue';


export default {
    name: "consulta",
    components: {
        ConfirmDialogue
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
    },
    data() {

        return {
            date: new Date().toISOString().substr(0, 10),
            menu: false,
            modal: false,
            menu2: false,
            multiLine: true,
            snackbar: false,
            panel: [],
            items: 3,
            alert: false,
            currentPaciente: {
                id: null,
                nombre: "",
                apellido: "",
                telefono: "",
                documento: ""
            },
            consulta: {
                idConsulta: "",
                notasConsulta: "",
                fechaConsulta: "",
                realizadaPor: "",
                seguimientoConsulta: false
            }
        };
    },

    methods: {

        async goToList() {
            const ok = await this.$refs.confirmDialogue.show({
                title: 'Volver al listado?',
                message: 'Todos los cambios se perderan.',
                okButton: 'Volver',
            })
            // If you throw an error, the method will terminate here unless you surround it wil try/catch
            if (ok) {
                this.$router.push("/pacientes");
            } else {
                // Doing nothing now, can throw an alert() too
            }
        },
        showEditedTime() {
            var editedTime = new Date();
            editedTime = editedTime.toLocaleTimeString(navigator.language, {
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
            });
            return editedTime
        },
        getPaciente(id) {
            PacienteDataService.get(id)
                .then(response => {
                    this.currentPaciente = response.data;
                    console.log(response.data);
                })
                .catch(e => {
                    console.log(e);
                });
        },
        saveConsulta() {
            var data = {
                idConsulta: this.currentPaciente.id,
                notasConsulta: this.consulta.notasConsulta,
                seguimientoConsulta: this.consulta.seguimientoConsulta,
                fechaConsulta: this.date

            };
            console.log(data);

            ConsultaDataService.addConsulta(data)
                .then((response) => {
                    //this.idConsulta.id = response.data.id;
                    console.log(response.data);
                    this.submitted = true;
                    this.$router.push("/pacientes");
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        newConsulta() {
            this.submitted = false;
            this.consulta = {};
        },
    },
    mounted() {
        this.getPaciente(this.$route.params.id)
    }
};
</script>

<style>
/* Hide up and down buttons on numeric type fields */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
</style>
